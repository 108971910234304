/* define loading */

export const showLoading = function showLoading() {
    document.body.classList.add("sonphat-loading");
};

export const hideLoading = function hideLoading() {
    document.body.classList.remove("sonphat-loading");
};

export const showSlowLoading = function showSlowLoading() {
    document.querySelector("#sonphat-SlowLoading").style.display = "block";
};

export const hideSlowLoading = function hideSlowLoading() {
    document.querySelector("#sonphat-SlowLoading").style.display = "none";
};

export default {
    show: showLoading,
    hide: hideLoading
};
