<template>
    <div class="row align-center">
        <i
            :class="` mdi mdi-${
                toast.type == 'error'
                    ? 'alert-circle-outline'
                    : toast.type == 'warning'
                    ? 'alert'
                    : 'check-circle-outline'
            }`"
            style="font-size: 30px"
        ></i>
        <div class="pl-2">
            {{ toast.message }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        toast: {
            type: Object,
            default: () => ({ type: "error", message: "" }),
        },
    },
};
</script>