import { TimeOut } from "../configs/base";
import Core from "@sonphat/core";
import AuthApi from "../bootstrap/AuthApi";

const SP = window.SP;
const $Core = new Core({
    // Cấu hình xác thực truy cập, bắt buộc có nếu muốn tải tải trình xác thực
    // Auth: new AuthApi,
    Auth: {
        Keycloak: SP["keycloak"] || {
            URL: "https://dangnhap.sbus.vn/auth",
            Realm: "Sbus.vn",
            ClientId: "LenhDienTu",
        },
    },
    // cấu hình cho api
    Api: {
        BaseURL: SP["API_Default_BaseUrl"] || "https://localhost:5001/",

        Timeout: TimeOut, // (ms), 5s === 5000 ms

        NoExceptionHttpStatus: false,
        Custom: (cfg) => {
            var auth = new AuthApi();
            if (cfg.headers) {
                // var tokenParsed = JSON.parse(localStorage.getItem("token")) || null;
                cfg.headers["Authorization"] = `Bearer ${auth.Token}`;
                cfg.headers["Fe_Version"] = window.SP["Fe_Version"] || "";
            }
            return cfg;
        },
    },
    Helper: {
        // cấu hình helper
        Format: {
            // cấu hình default format, $Core.Helper.Format.Datetime(new Date(), format)
            DatetimeDefaultFormat: `{yyyy}/{mm}/{dd} {hh}:{ii}:{ss}.{ms}`,
        },
    },
});
$Core.AuthApi = new AuthApi();
function configApi(keyApi, keyConfigApi) {
    Object.defineProperty($Core.Api, keyApi, {
        get() {
            var self = this,
                args = arguments;
            args;
            return function () {
                return self.Make.apply(self, arguments).Config((c) => {
                    var userConfig =
                        $Core && $Core.AuthApi && $Core.AuthApi.TokenParsed
                            ? $Core.AuthApi.TokenParsed[
                                  `CauHinhRiengChoAppLaiXe_${keyConfigApi}`
                              ]
                            : null;
                    c.baseURL =
                        userConfig ||
                        window.SP[keyConfigApi] ||
                        "https://localhost:5003/";
                    return c;
                });
            };
        },
    });
}
configApi("LenhMobile", "API_LenhDienTu");
// configApi("BenXe", "API_BenXe"); // Bỏ
configApi("CauHinhHeThong", "API_CauHinhHeThong"); // Bỏ
configApi("DiemXuongCuaKhachHang", "API_DiemXuongCuaKhachHang");
configApi("DoanhNghiepVanTai", "API_DoanhNghiepVanTai"); // Bỏ
configApi("DoiSoat", "API_DoiSoat");
configApi("DonHang", "API_DonHang");
configApi("TaiKhoan", "API_TaiKhoan");
configApi("TuyenDuong", "API_TuyenDuong"); //Bỏ
configApi("Xe", "API_Xe"); // Bỏ
configApi("ChuyenDi", "API_ChuyenDi");
configApi("Print", "API_Print");
configApi("HangHoa", "API_HangHoa");
configApi("ThongTin", "API_ThongTin");
configApi("PhienBan", "API_PhienBan");
configApi("BaoCao", "API_BaoCao");
configApi("QuanLyLenh", "API_QuanLyLenh");
configApi("QuanLyThongTinLenh", "API_QuanLyThongTinLenh");
configApi("KySoDienTu", "API_KySoDienTu");
configApi("ThongBao", "API_ThongBao");
configApi("QuanLyThongTinVe", "API_QuanLyThongTinVe"); // Thêm mới

$Core.Requests = {};

$Core.Requests.Get = async function RequestGet({ ServicesBE, url, dataGet }) {
    try {
        let result = await $Core.Api[ServicesBE || "Make"](url, dataGet).Get();
        return result.Data;
    } catch (ex) {
        if (ex.message == "Network Error")
            throw Error("Không kết nối được đến máy chủ!");
        throw ex;
    }
};

$Core.Requests.Post = async function RequestPost({
    ServicesBE,
    url,
    dataGet,
    dataPost,
}) {
    try {
        let resp = await $Core.Api[ServicesBE](url, dataGet || null).Post(
            dataPost
        );
        return resp.Data;
    } catch (ex) {}
};

export default $Core;
