export default class TaiKhoan {
    constructor(id, soDienThoai = "", hoTen, email, ghiChu) {
        this.id = id;
        this.soDienThoai = soDienThoai;
        this.hoTen =
            hoTen || window.$Core.AuthApi.TokenParsed != null
                ? `${window.$Core.AuthApi.TokenParsed.family_name || ""} ${
                      window.$Core.AuthApi.TokenParsed.given_name || ""
                  }`
                : "";
        this.email = email;
        this.ghiChu = ghiChu;
        this.ngaySinh = new Date();
        this.diaChi = "";
        this.namBatDauHanhNghe = 0;
        this.soNamKinhNghiem = 0;
        this.soGPLX = "";
        this.hangBang = "";
        this.ngayBatDauHieuLuc = new Date();
        this.thoiHanHieuLuc = new Date();
        this.coQuanCap = "";
        this.ngayCap = new Date();
    }
    get() {
        return this;
    }
    async layThongTinQuangCao() {
        window.$loading.show();
        let rs = await window.$Core.Api.QuanLyThongTinVe(
            window.$i18n.global.t("url.ThongTinQuangCao")
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return [];
    }
    async layThongTinChucNang() {
        window.$loading.show();
        let rs = await window.$Core.Api.QuanLyThongTinVe(
            window.$i18n.global.t("url.ThongTinChucNang")
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return [];
    }
    async layThongTinKhachHang(soDienThoai) {
        window.$loading.show();
        let rs = await window.$Core.Api.Make(
            window.$i18n.global.t("url.ThongTinKhachHang"),
            {
                soDienThoai: soDienThoai || this.soDienThoai
            }
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return null;
    }
    async layCauHinhChung() {
        window.$loading.show();
        let rs = await window.$Core.Api.QuanLyThongTinVe(
            window.$i18n.global.t("url.CauHinhChung")
        ).Get();
        window.$loading.hide();
        return rs;
    }
    cauHinhChung() {
        return JSON.parse(localStorage.getItem("ThongTinCauHinhChung"));
    }
    async layThongTinCaNhan() {
        // let self = this;
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LayThongTinCaNhan")
        ).Get();
        window.$loading.hide();
        return rs;
    }
    async layDanhSachTinh() {
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LayDSTinh")
        ).Get();
        window.$loading.hide();
        return rs;
    }
    async layDanhSachHuyen(idTinh) {
        if (!idTinh) {
            throw new Error("Không có idTinh");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.LayDSHuyen"),
            {
                IdTinh: idTinh
            }
        ).Get();
        window.$loading.hide();
        return rs;
    }
    async chinhSuaThongTinCaNhan(tenTruong, noiDung) {
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.ChinhSuaThongTinCaNhan")
        ).Post({ tenTruong: tenTruong, noiDung: noiDung });
        window.$loading.hide();
        return rs;
    }
    async chinhSuaDiaChiCaNhan(idHuyen, diaChi) {
        window.$loading.show();
        let rs = await window.$Core.Api.LenhMobile(
            window.$i18n.global.t("url.ChinhSuaDiaChiCaNhan")
        ).Post({ idHuyen: idHuyen, diaChi: diaChi });
        window.$loading.hide();
        return rs;
    }
}
