<template>
    <swiper :pagination="true" :autoplay="true" :zoom="true">
        <swiper-slide
            v-for="(item, index) in DataSource"
            :key="index"
            style="width: 100%; height: 100px"
            ><ion-img :src="item.hinhNenQuangCao || item.hinhAnhUrl"></ion-img
        ></swiper-slide>
    </swiper>
</template>
<script>
import "@ionic/vue/css/ionic-swiper.css";
import "swiper/swiper.scss";
import { Pagination, Autoplay, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { IonImg } from "@ionic/vue";
export default {
    props: {
        ShowNavButton: {
            type: Boolean,
            default: false,
        },
        Loop: {
            type: Boolean,
            default: true,
        },
        ShowIndicator: {
            type: Boolean,
            default: true,
        },
        Height: {
            type: String,
            default: "100%",
        },
        SlideshowDelay: {
            type: Number,
            default: 3000,
        },
        DataSource: {
            type: Array,
            default: () => {
                [];
            },
        },
    },
    components: {
        Swiper,
        SwiperSlide,
        IonImg,
        Pagination,
        Autoplay,
        Zoom,
    },
    setup() {
        // Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
        const slideOpts = {
            initialSlide: 0,
            speed: 400,
            autoplay: {
                delay: 3000,
                width: "100%",
            },
        };
        return { slideOpts };
    },

    data() {
        return {
            data: [],
        };
    },
    methods: {
        handlePropertyChange: function (e) {
            if (Array.isArray(e.value) && e.value.length > 0) {
                if (e.value[0].thoiGianHienThi > 0) {
                    this.delay = e.value[0].thoiGianHienThi;
                }
            }
        },
    },
};
</script>