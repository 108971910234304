<template>
    <DxPopup
        :visible="dialog"
        :close-on-outside-click="closeOnOutsideClick"
        :show-close-button="false"
        :show-title="false"
        :width="width"
        :height="height"
        :maxHeight="maxHeight"
        :shading="shading"
        :onHidden="onHiddenPopup"
        :copyRootClassesToWrapper="true"
        container=".dx-viewport"
        position="bottom"
        style="position: relative"
        :animation="{
            show: {
                type: 'slide',
                duration: 400,
                from: {
                    position: {
                        my: 'top',
                        at: 'bottom',
                        of: 'window',
                    },
                },
            },
            hide: {
                type: 'slide',
                duration: 400,
                to: {
                    position: {
                        my: 'top',
                        at: 'bottom',
                        of: 'window',
                    },
                },
            },
        }"
        :wrapperAttr="{
            class: `${classPopup}`,
        }"
    >
        <template #content>
            <div class="row" style="height: auto">
                <div class="xs12" style="position: relative">
                    <div style="position: absolute" v-if="showCloseButton">
                        <DxButton
                            type="normal"
                            styling-mode="text"
                            icon="mdi mdi-close"
                            style="
                                position: absolute;
                                top: -17px;
                                left: -14px;
                                z-index: 1;
                            "
                            @click="$emit('close')"
                        />
                    </div>
                    <div
                        class="row"
                        :style="
                            useButton && (useButtonLeft || useButtonRight)
                                ? 'height: calc(100% - 48px)'
                                : 'height: auto'
                        "
                    >
                        <div class="xs12">
                            <div
                                v-if="title"
                                class="row title justify-center text-xs-center pb-2"
                            >
                                {{ title }}
                            </div>
                            <!-- :style="`height: ${heightScrollView}`" style="overflow: scroll" -->
                            <DxScrollView
                                id="scrollview"
                                ref="scrollViewWidget"
                                width="100%"
                                :height="
                                    heightScrollView
                                        ? heightScrollView
                                        : title
                                        ? 'calc(100% - 36px)'
                                        : ''
                                "
                                :scroll-by-content="true"
                            >
                                <slot name="content"></slot>
                            </DxScrollView>
                        </div>
                    </div>
                    <!-- style="flex-basis: 50px" -->
                    <div
                        class="row align-center justify-space-between"
                        style="border-top: 1px solid #dadce0; height: 50px"
                        v-if="useButton && (useButtonLeft || useButtonRight)"
                    >
                        <!-- :class="`${directionButton} ${useButtonLeft? 'justify-start' : useButtonRight ? 'justify-end' : 'justify-space-between'}`" -->
                        <slot name="buttonLeft">
                            <ion-button
                                v-if="useButtonLeft"
                                color="primary"
                                :fill="typeButtonLeft || 'clear'"
                                :class="`${
                                    directionButton == 'row' && !fullWidth ? 'mr-2' : ''
                                } ${fullWidth ? 'full-width' : ''}`"
                                @click="$emit('close', false)"
                            >
                                {{ buttonTextLeft }}
                            </ion-button>
                            <!-- <DxButton
                                                v-if="useButtonLeft"
                                                :text="buttonTextLeft"
                                                :type="typeButtonLeft"
                                                styling-mode="text"
                                                :class="`rounder ${
                                                    directionButton == 'row' &&
                                                    !fullWidth
                                                        ? 'mr-2'
                                                        : ''
                                                } ${
                                                    fullWidth
                                                        ? 'full-width'
                                                        : ''
                                                }`"
                                                style="border-radius: 10px"
                                                @click="$emit('close', false)"
                                            /> -->
                        </slot>
                        <slot name="buttonRight">
                            <ion-button
                                v-if="useButtonRight"
                                color="primary"
                                fill="solid"
                                :class="`${
                                    directionButton == 'row' && !fullWidth
                                        ? 'ml-2'
                                        : 'mb-2'
                                } ${fullWidth ? 'full-width' : ''}`"
                                @click="$emit('close', true)"
                            >
                                {{ buttonTextRight }}
                            </ion-button>
                            <!-- <DxButton
                                            v-if="useButtonRight"
                                            :text="buttonTextRight"
                                            type="default"
                                            styling-mode="contained"
                                            :class="`rounder ${
                                                directionButton == 'row' &&
                                                !fullWidth
                                                    ? 'ml-2'
                                                    : 'mb-2'
                                            } ${fullWidth ? 'full-width' : ''}`"
                                            style="border-radius: 10px"
                                            @click="$emit('close', true)"/> -->
                        </slot>
                    </div>
                </div>
            </div>
        </template>
    </DxPopup>
</template>
<script>
import { DxScrollView, DxPopup, DxButton } from "devextreme-vue";
import { IonButton } from "@ionic/vue";
import { mapMutations } from "vuex";
export default {
    props: {
        width: { type: String, default: "100%" },
        height: { type: String, default: "60%" },
        heightScrollView: { type: String, default: "auto" },
        // heightScrollView: { type: String, default: "calc(100% - 34px)" },
        shading: { type: Boolean, default: true },
        useButtonRight: { type: Boolean, default: true },
        useButtonLeft: { type: Boolean, default: true },
        useButton: { type: Boolean, default: true },
        showCloseButton: { type: Boolean, default: false },
        dialog: { type: Boolean, default: false },
        buttonTextLeft: { type: String, default: "Trở về" },
        buttonTextRight: { type: String, default: "Đồng ý" },
        title: { type: String, default: "Tiêu đề" },
        directionButton: { type: String, default: "row" },
        typeButtonLeft: { type: String, default: "default" },
        classPopup: { type: String, default: "popup-style" },
        closeOnOutsideClick: { type: Boolean, default: true },
        fullWidth: { type: Boolean, default: false },
        column: { type: Boolean, default: true },
        maxHeight: { type: String || Number, default: null },
        CheckResetSoDoCho: { type: Boolean, default: true },
    },
    components: {
        DxPopup,
        DxButton,
        DxScrollView,
        IonButton,
    },
    data() {
        return { dialogThongBao: true };
    },
    methods: {
        onHiddenPopup(e) {
            this.$store.commit("DVVTLenh/SET_DIALOG_LENH", false);
            this.$store.commit("DVVTLenh/SET_DIALOG_BAO_MAT", false);
            this.$store.commit("DVVTLenh/SET_DIALOG_KY_LAI_LENH", false);
            this.$store.commit("DVVTLenh/Set", {
                key: "dialogDungHanhTrinh",
                data: false,
            });
            this.$emit("update:dialog", false);
            this.setChinhSuaSoatVe(false);
            this.setDuocQuetMa(true);
            if (this.CheckResetSoDoCho == true) {
                this.resetSoDoCho();
            }
        },
        ...mapMutations("Ve", ["resetSoDoCho", "setChinhSuaSoatVe"]),
        ...mapMutations("QrCode", ["setDuocQuetMa"]),
    },
    // watch: {
    //     dialog() {
    //         if (!this.dialog) {
    //             this.$emit("close", false);
    //         }
    //     },
    // },
};
</script>
<style lang="scss" scoped>
::v-deep.rounder .dx-button-text {
    text-transform: unset !important;
}
.dx-overlay-content {
    height: auto !important;
}
</style>
