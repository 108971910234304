<template>
    <ion-card class="ma-3">
        <!-- <div class="card container-md"> -->
        <div v-if="!model.MaLenh" class="text-xs-center pa-2" style="line-height: 21px">
            Không có dữ liệu lệnh hiện tại!
        </div>
        <div v-else>
            <ion-grid fixed>
                <div class="pa-3 pb-0">
                    <div class="row" style="word-break: break-all">
                        <div class="xs8">
                            <div
                                class="row font-bold font-14 align-center"
                                :class="model.laBenDi ? 'color-chieuDi' : 'color-chieuVe'"
                            >
                                <i class="mdi mdi-bus-stop pr-2"></i>
                                <div
                                    class="font-bold font-14"
                                    style="white-space: break-spaces"
                                >
                                    {{ `${model.benDi}` }}
                                </div>
                            </div>
                            <div class="row justify-space-between align-center">
                                <div class="grow">
                                    <div class="row align-center font-bold font-14">
                                        <i
                                            class="mdi mdi-clock-outline pr-2"
                                            :class="
                                                model.laBenDi
                                                    ? 'color-chieuDi'
                                                    : 'color-chieuVe'
                                            "
                                        ></i>
                                        {{ $Helper.formatDateTime(model.GioXuatBenKH) }}
                                    </div>
                                </div>
                            </div>

                            <div class="row font-bold font-14 align-center">
                                <i
                                    class="mdi mdi-card-bulleted-outline pr-2"
                                    :class="
                                        model.laBenDi ? 'color-chieuDi' : 'color-chieuVe'
                                    "
                                ></i>
                                {{ `${model.BienKiemSoat}` }}
                            </div>
                            <div class="row font-bold font-14 align-center">
                                <i
                                    class="mdi mdi-map-marker-down pr-2"
                                    :class="
                                        model.laBenDi ? 'color-chieuDi' : 'color-chieuVe'
                                    "
                                ></i>
                                <div
                                    class="font-bold font-14"
                                    style="white-space: break-spaces"
                                >
                                    {{ `${model.benDen}` }}
                                </div>
                            </div>

                            <div class="row align-center font-bold font-14">
                                <i
                                    class="mdi mdi-seat-passenger pr-2"
                                    :class="
                                        model.laBenDi ? 'color-chieuDi' : 'color-chieuVe'
                                    "
                                ></i>
                                {{ `${ThongTinChuyenDi.soKhachTrenXe || 0} khách` }}
                            </div>
                        </div>
                        <div class="xs4 ml-3">
                            <div
                                class="row justify-center text-xs-center pt-1 font-14 font-bold"
                                :style="`color:${model.MaMau}; word-break: break-word;`"
                            >
                                {{ model.TrangThai }}
                            </div>
                            <div class="row justify-center">
                                <img
                                    v-if="model && model.QrCode && !model.LaLenhGiay"
                                    :src="QrCodeLink"
                                    style="
                                        width: 98px !important;
                                        height: 100px !important;
                                        max-width: unset;
                                    "
                                    alt=""
                                    v-on:click="
                                        model.IdTrangThai != 1 ? xemChiTietLenh() : ''
                                    "
                                />
                            </div>
                            <div
                                class="row justify-center text-xs-center font-10 font-medium mt-1"
                                v-if="model.LaLenhGiay"
                            >
                                MÃ LỆNH:
                            </div>
                            <div
                                class="row justify-center text-xs-center font-10 font-bold"
                                :class="{
                                    'color-success font-12': model.LaLenhGiay,
                                }"
                            >
                                {{ model.MaLenh }}
                            </div>
                            <div
                                class="row justify-center text-xs-center font-10 font-medium mt-1"
                                style="word-break: break-word"
                                v-if="model.LaLenhGiay"
                            >
                                MÃ CỦA CĐBVN CẤP:
                            </div>
                            <div
                                class="row justify-center text-xs-center font-12 font-bold color-success"
                                v-if="model.LaLenhGiay"
                            >
                                {{ model.MaCDBCap }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="font-14 color-warning row justify-center text-xs-center"
                        style="overflow: hidden"
                    >
                        {{ message[count] }}
                    </div>
                </div>
                <!-- <ion-col size="12">
                        <ion-button
                            color="primary"
                            expand="block"
                            v-on:click="soDoCho"
                        >
                            Sơ đồ chỗ
                        </ion-button>
                    </ion-col> -->
                <div
                    class="row mt-2"
                    style="border-top: 1px solid #dadce0"
                    v-if="model.IdTrangThai != 1"
                >
                    <div
                        :class="{
                            xs6: !model.LaLenhGiay,
                            xs12: model.LaLenhGiay,
                        }"
                        :style="
                            !model.LaLenhGiay ? 'border-right: 1px solid #dadce0' : ''
                        "
                    >
                        <ion-button
                            :disabled="model.IdTrangThai == 1"
                            color="warning"
                            expand="full"
                            fill="clear"
                            class="ion-button-no-margin"
                            v-on:click="xemChiTietLenh"
                        >
                            Chi tiết lệnh
                        </ion-button>
                    </div>
                    <div
                        :class="{
                            xs6: !model.LaLenhGiay,
                        }"
                        v-if="!model.LaLenhGiay"
                    >
                        <ion-button
                            :color="isCheckVisibleButton(model) ? 'success' : 'dark'"
                            expand="full"
                            fill="clear"
                            class="ion-button-no-margin"
                            :disabled="!isCheckVisibleButton(model)"
                            v-if="model.IdLenh == ChiTietLenhDangThucHien.guidLenh"
                            v-on:click="clickXeDenBen(model)"
                        >
                            Đến bến
                        </ion-button>
                        <ion-button
                            expand="full"
                            fill="clear"
                            class="ion-button-no-margin"
                            :disabled="
                                model.IdTrangThai != $t('TrangThaiLenh.ChoBenDiKy') &&
                                model.IdTrangThai !=
                                    $t('TrangThaiLenh.DangThucHienHanhTrinh') &&
                                model.IdTrangThai !=
                                    $t('TrangThaiLenh.ChoXacNhanTraKhach') &&
                                model.IdTrangThai != $t('TrangThaiLenh.BenDiDaKy') &&
                                model.IdTrangThai != $t('TrangThaiLenh.HoanThanh')
                            "
                            v-on:click="NhanLenh(model)"
                            v-else
                        >
                            Tiếp nhận
                        </ion-button>
                    </div>
                </div>
                <div class="row mt-2" style="border-top: 1px solid #dadce0" v-else>
                    <!-- <div class="xs6" style="border-right: 1px solid #dadce0">
                        <ion-button
                            :color="
                                isCheckVisibleButton(model) ? 'success' : 'dark'
                            "
                            expand="full"
                            fill="clear"
                            size="small"
                            class="ion-button-no-margin"
                            :disabled="!isCheckVisibleButton(model)"
                            v-on:click="clickXeDenBen(model)"
                        >
                            Đến bến
                        </ion-button>
                    </div>
                    <div class="xs6">
                        <ion-button
                            :disabled="model.IdTrangThai == 1"
                            color="primary"
                            expand="full"
                            fill="clear"
                            size="small"
                            class="ion-button-no-margin"
                            v-on:click="xemChiTietLenh"
                        >
                            Chi tiết lệnh
                        </ion-button>
                    </div> -->

                    <div
                        class="xs6 text-xs-center"
                        style="border-right: 1px solid #dadce0"
                    >
                        <DxButton
                            text="Từ chối"
                            type="danger"
                            styling-mode="text"
                            width="100%"
                            @click="TuChoiLenh(model)"
                        />
                    </div>
                    <div class="xs6 text-xs-center">
                        <DxButton
                            text="Tiếp nhận"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            @click="NhanLenh(model)"
                        />
                    </div>
                </div>
            </ion-grid>
        </div>
    </ion-card>

    <PopupVue
        height="auto"
        v-if="ifDialogKetThucChuyenDi"
        v-model:dialog="dialogKetThucChuyenDi"
        title=""
        buttonTextLeft="Hủy"
        classPopup="popup-padding-16"
        @close="xacNhanKetThucChuyenDi"
        heightScrollView="auto"
    >
        <template #content>
            <ThongBaoVue
                :message="ParamThongBao.message"
                :state="ParamThongBao.state"
                :title="ParamThongBao.title"
            />
        </template>
    </PopupVue>
    <PopupVue
        height="auto"
        v-if="ifDialogTuChoiLenh"
        v-model:dialog="dialogTuChoiLenh"
        title="Từ chối lệnh"
        buttonTextRight="Xác nhận"
        buttonTextLeft="Hủy"
        classPopup="popup-padding-bottom-8 popup-padding-right-16 popup-padding-left-16 popup-padding-top-16"
        @close="closeTuChoiLenh"
    >
        <template #content>
            <PopupTuChoiLenhVue
                :data="LenhDangChon"
                ref="TuChoiLenh"
                :key="`${componentKey}-1`"
            />
        </template>
    </PopupVue>
    <PopupVue
        height="auto"
        v-if="ifDialogTiepNhanLenh"
        v-model:dialog="dialogTiepNhanLenh"
        :title="titleNhanLenh"
        buttonTextRight="Xác nhận"
        buttonTextLeft="Hủy"
        heightScrollView="0px"
        @close="closeTiepNhanLenh"
        classPopup="popup-padding-16"
    >
    </PopupVue>

    <!-- ====================================Xác nhận chuyển trang Danh sách lệnh cần hoàn thành ========================================= -->
    <PopupVue
        height="auto"
        v-if="ifDialogChuyenTrangHoanThanhTatCaLenh"
        v-model:dialog="dialogChuyenTrangHoanThanhTatCaLenh"
        title=""
        buttonTextRight="Tiếp tục"
        buttonTextLeft="Hủy"
        heightScrollView="auto"
        @close="closeChuyenTrangHoanThanhTatCaLenh"
        classPopup="popup-padding-16"
    >
        <template #content>
            <ThongBaoVue
                :message="ParamThongBao.message"
                :state="ParamThongBao.state"
                :title="ParamThongBao.title"
            />
        </template>
    </PopupVue>
</template>
<script>
import { IonButton, IonIcon, IonRow, IonCard, IonCol, IonGrid } from "@ionic/vue";
import { mapState, mapActions } from "vuex";
import PopupVue from "@/components/_Common/Popup";
import ThongBaoVue from "@/components/_Common/ThongBao";
import { DxButton } from "devextreme-vue";
import PopupTuChoiLenhVue from "../views/Quan-Ly-Lenh/components/PopupTuChoiLenh";
import LaiXe from "../../class/LaiXe";
export default {
    components: {
        IonButton,
        IonIcon,
        IonRow,
        IonCard,
        IonCol,
        IonGrid,
        PopupVue,
        ThongBaoVue,
        DxButton,
        PopupTuChoiLenhVue,
    },
    props: {
        model: {
            type: Object,
            default: () => {},
        },
        ThongTinChuyenDi: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            error: true,
            lenhHienTai: null,
            dialogKetThucChuyenDi: false,
            dataKetThucChuyendi: {},
            count: 0,
            message: [
                "👍 Xuất trình lệnh điện tử khi thanh kiểm tra!",
                "⚠️ Chỉ chọn đến bến khi bến đi đã ký!",
                "✌️ Chọn đến bến khi kết thúc hành trình!",
            ],
            LenhDangChon: {},
            dialogTuChoiLenh: false,
            dialogTiepNhanLenh: false,
            ifDialogKetThucChuyenDi: false,
            ifDialogTuChoiLenh: false,
            ifDialogTiepNhanLenh: false,
            ifDialogChuyenTrangHoanThanhTatCaLenh: false,
            dialogChuyenTrangHoanThanhTatCaLenh: false,
            titleNhanLenh: null,
            ChiTietLenhDangThucHien: {},
            DanhSachLenhCanHoanThanh: [],
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),

        QrCodeLink() {
            if (!this.model || !this.model?.QrCode) return "#";
            // let fcolor = this.renderColorQr(this.model?.laBenDi);
            let qrdata = this.model?.QrCode;
            return (
                `https://image-charts.com/chart?` +
                [
                    "cht=qr",
                    "icqrb=FFFFFF00",
                    // "icqrf=" + fcolor,
                    "chs=88x88",
                    "choe=UTF-8",
                    "chld=L",
                    "chl=" + qrdata,
                ].join("&")
            );
        },
    },
    watch: {
        dialogTuChoiLenh() {
            if (!this.dialogTuChoiLenh) {
                this.$refs.TuChoiLenh.model.LyDo = "";
                this.$refs.TuChoiLenh.$refs.formValidation.instance.reset();
                this.NgatDOMPopup("ifDialogTuChoiLenh");
            }
        },
        dialogTiepNhanLenh() {
            if (!this.dialogTiepNhanLenh) {
                this.NgatDOMPopup("ifDialogTiepNhanLenh");
            }
        },
        dialogKetThucChuyenDi() {
            if (!this.dialogKetThucChuyenDi) {
                this.NgatDOMPopup("ifDialogKetThucChuyenDi");
            }
        },
        dialogChuyenTrangHoanThanhTatCaLenh() {
            if (!this.dialogChuyenTrangHoanThanhTatCaLenh) {
                this.NgatDOMPopup("ifDialogChuyenTrangHoanThanhTatCaLenh");
            }
        },
    },
    methods: {
        ...mapActions("Lenh", ["layChiTietLenhDangThucHien"]),
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        soDoCho() {
            this.$router.push({
                path: "/tabs/tab3",
                query: {},
            });
        },
        xemDanhSachLenh() {
            this.$router.push({
                path: "/tabs/LenhDienTu",
            });
        },
        xemChiTietLenh() {
            this.$router.push({
                path: "/tabs/LenhDienTu",
                query: {
                    isChiTiet: true,
                    guidLenh: this.model.IdLenh,
                    laLenhGiay: this.model.LaLenhGiay,
                },
            });
        },
        isCheckVisibleButton(model) {
            return (
                model.IdTrangThai == this.$t("TrangThaiLenh.BenDiDaKy") ||
                model.IdTrangThai == this.$t("TrangThaiLenh.ChoXacNhanTraKhach") ||
                model.IdTrangThai == this.$t("TrangThaiLenh.DangThucHienHanhTrinh") ||
                model.IdTrangThai == this.$t("TrangThaiLenh.HoanThanh")
            );
        },
        clickXeDenBen(data) {
            this.dataKetThucChuyendi = data;
            this.ParamThongBao = {
                state: "Warning",
                title: "Xác nhận xe đã đến bến",
                message: "Bạn có chắc chắn đã kết thúc chuyến đi?",
            };
            this.ifDialogKetThucChuyenDi = true;
            this.dialogKetThucChuyenDi = true;
        },
        async xacNhanKetThucChuyenDi(param) {
            if (!param) {
                this.dialogKetThucChuyenDi = false;
                return;
            }
            let toast = {
                type: "error",
                message: "",
                visible: true,
            };
            try {
                let dataPost = {
                    idLenh: this.dataKetThucChuyendi.IdLenh,
                    ToaDo: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                };
                let rs = await this.$store.dispatch("Lenh/KetThucChuyenDi", dataPost);
                if (rs.Data.status == true) {
                    this.dialogKetThucChuyenDi = false;
                    this.reloadDataLenh();
                    this.thongBaoThanhCong("Kết thúc chuyến đi thành công!");
                } else {
                    toast.message = rs.Data.message;
                    this.emitter.emit("onToast", toast);
                }
            } catch (error) {
                console.log("🚀 = error", error);
                toast.message = "Lỗi hệ thống!";
                this.emitter.emit("onToast", toast);
            } finally {
                this.dialogKetThucChuyenDi = false;
            }
        },
        thongBaoLoi(message) {
            let toast = {
                message: message,
                type: "error",
                visible: true,
            };
            this.emitter.emit("onToast", toast);
        },
        thongBaoThanhCong(message) {
            let toast = {
                message: message,
                type: "success",
                visible: true,
            };
            this.emitter.emit("onToast", toast);
        },
        TuChoiLenh(item) {
            this.LenhDangChon = { ...item, maLenh: item.MaLenh };
            this.ifDialogTuChoiLenh = true;
            this.dialogTuChoiLenh = true;
        },
        async closeTuChoiLenh(param) {
            if (!param) {
                this.dialogTuChoiLenh = false;
                return;
            }
            try {
                let valid = this.$refs.TuChoiLenh.$refs.formValidation.instance.validate();
                if (!valid.isValid) {
                    return;
                }
                let laiXe = new LaiXe();
                laiXe.lyDo = this.$refs.TuChoiLenh.model.LyDo;
                let rs = await laiXe.tuChoiLenh(
                    this.LenhDangChon.IdLenh,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                this.dialogTuChoiLenh = false;
                if (!rs) {
                    throw this.thongBaoLoi("Lỗi hệ thống!");
                }
                if (!rs.Data.status) {
                    return this.thongBaoLoi(rs.Data.message);
                }
                this.reloadDataLenh();
                this.thongBaoThanhCong("Từ chối lệnh thành công!");
                this.$refs.TuChoiLenh.model.LyDo = "";
                this.$refs.TuChoiLenh.$refs.formValidation.instance.reset();
            } catch (error) {
                this.thongBaoLoi("Lỗi hệ thống!");
            }
        },
        NhanLenh(item) {
            return this.KiemTraTruocKhiTiepNhanLenh(item);
        },
        async KiemTraTruocKhiTiepNhanLenh(item) {
            try {
                this.LenhDangChon = { ...item, maLenh: item.MaLenh };
                let rs = await this.$Events.RequestGet({
                    ServicesBE: "LenhMobile",
                    url: this.$t("urlLXQuanLyLenh.LaiXeKiemTraTruocKhiTiepNhanLenh"),
                    dataGet: null,
                    msgSuccess: "",
                    msgError: "",
                    msgCatch: "",
                });
                if (!rs.status) {
                    if (rs.errorCode == 1214) {
                        this.DanhSachLenhCanHoanThanh = rs.data || [];
                        localStorage.setItem(
                            "DanhSachLenhCanHoanThanh",
                            JSON.stringify(this.DanhSachLenhCanHoanThanh),
                        );
                        this.$router.push({
                            path: "/Danh-Sach-Lenh-Can-Hoan-Thanh",
                            query: {
                                prePath: "/tabs/tab1",
                            },
                        });
                        this.$Helper.ThongBaoToast(
                            "warning",
                            "Lái xe có chuyến đi chưa hoàn thành!",
                        );

                        // this.ParamThongBao.title = `!`;
                        // this.ParamThongBao.message = `Bạn cần hoàn thành tất cả các chuyến đi trước khi tiếp nhận lệnh mới, bạn có muốn tiếp tục?`;
                        // this.ParamThongBao.state = "Warning";
                        // this.ifDialogChuyenTrangHoanThanhTatCaLenh = true;
                        // this.dialogChuyenTrangHoanThanhTatCaLenh = true;
                    } else if (rs.errorCode == 1215) {
                        this.ChonLenhChuyen();
                    } else {
                        this.$Helper.ThongBaoToast("error", rs.message);
                    }
                } else {
                    this.titleNhanLenh = `Bạn có chắc muốn tiếp nhận lệnh điện tử ${item.MaLenh}?`;
                    this.ifDialogTiepNhanLenh = true;
                    this.dialogTiepNhanLenh = true;
                }
            } catch (error) {
                console.log("🚀 ~ file: error:", error);
            }
        },
        closeChuyenTrangHoanThanhTatCaLenh(param) {
            this.dialogChuyenTrangHoanThanhTatCaLenh = false;
            if (!param) {
                return;
            }
            localStorage.setItem(
                "DanhSachLenhCanHoanThanh",
                JSON.stringify(this.DanhSachLenhCanHoanThanh),
            );
            this.$router.push({
                path: "/Danh-Sach-Lenh-Can-Hoan-Thanh",
                query: {
                    prePath: "/tabs/tab1",
                },
            });
        },
        async closeTiepNhanLenh(param) {
            if (!param) {
                this.dialogTiepNhanLenh = false;
                return;
            }
            try {
                this.$startLoading;
                let rs = await new LaiXe().tiepNhanLenh(
                    this.LenhDangChon.IdLenh,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                this.dialogTiepNhanLenh = false;
                this.$stopLoading;

                if (!rs) {
                    throw this.thongBaoLoi("Lỗi hệ thống!");
                }
                if (!rs.Data.status) {
                    this.thongBaoLoi(rs.Data.message);
                    return;
                }
                this.reloadDataLenh();
                this.thongBaoThanhCong("Tiếp nhận lệnh thành công!");
            } catch (error) {
                this.$stopLoading;
                this.thongBaoLoi("Lỗi hệ thống!");
            }
        },
        reloadDataLenh() {
            this.layChiTietLenhDangThucHienAction();
            this.$emit("reloadDanhSachLenhDangThucHien");
        },
        async layChiTietLenhDangThucHienAction() {
            let toast = {
                message: "",
                type: "error",
                visible: true,
            };
            try {
                let rs = await this.layChiTietLenhDangThucHien();
                if (!rs) {
                    this.toastThongBao("error", "Đã xảy ra lỗi. Vui lòng thử lại!");
                    this.emitter.emit("onToast", toast);
                    return;
                }
                if (!rs.Data.status) {
                    return;
                }
                this.ChiTietLenhDangThucHien = rs.Data.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.LoadXongDuLieu = true;
                if (!this.isChiTiet) {
                    this.isDanhSach = true;
                }
            }
        },
    },
    created() {},
    mounted() {
        setInterval(() => {
            this.count = this.count < this.message.length - 1 ? this.count + 1 : 0;
        }, 3000);
    },
};
</script>
<style scoped>
.color-chieuDi {
    color: #03a9f4;
}
.color-chieuVe {
    color: #13b000;
}
</style>
<style lang="scss" scoped>
.card {
    border-radius: 10px;
    box-shadow: $box_shadow;
}
</style>
